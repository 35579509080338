import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaYoutube } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { GrInstagram } from "react-icons/gr";
import { MdOutlineMail } from "react-icons/md";
import { logo } from "../assets/icons";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className='w-full bg-black text-white flex flex-col lg:flex-row justify-between items-start gap-10 lg:gap-2 px-5 py-10 lg:px-5 lg:py-20 relative'>
      <div className='flex flex-col justify-center items-start gap-2 w-full lg:w-[40%]'>
        <img
          src={logo}
          alt='icon'
          width={65}
          height={65}
          className='mt-2 me-4 mb-2 cursor-pointer'
        />
        {/* <h1 className='text-green-600 me-4 font-bold text-2xl'>CleanUp Home</h1> */}
        <p className='text-lg text-slate-200'>{t("Footer Text")}</p>
        <p className='flex items-center gap-1 text-[14px] text-slate-200'>
          <MdOutlineMail /> hallo@cleanup-home.de
        </p>
        <div className='flex justify-center items-center gap-4 mt-2' id='logos'>
          <a
            href='https://facebook.com/cleanuphome1'
            target='_blank'
            rel='noopener noreferrer'
            className='bg-gray-500 p-2vrounded-full cursor-pointer hover:bg-green-600'>
            <FaFacebookSquare />
          </a>
          {/* <span className='bg-gray-500 p-2vrounded-full cursor-pointer hover:bg-green-600'>
            <RiTwitterXFill />
          </span> */}
          <a
            href='https://instagram.com/cleanuphome1'
            target='_blank'
            rel='noopener noreferrer'
            className='bg-gray-500 p-2vrounded-full cursor-pointer hover:bg-green-600'>
            <GrInstagram />
          </a>
          {/* <span className='bg-gray-500 p-2vrounded-full cursor-pointer hover:bg-green-600'>
            <FaYoutube />
          </span> */}
        </div>
      </div>

      <div className='flex flex-col justify-center items-start lg:items-center w-full lg:w-[20%]'>
        <ul className='flex flex-col justify-center items-start gap-3'>
          <h1 className='text-xl font-semibold'>{t("Services")}</h1>
          <Link to='/services/general-cleaning'>
            <li className=' text-slate-300 cursor-pointer hover:text-green-300'>
              {t("General Cleaning")}
            </li>
          </Link>

          <Link to='/services/window-cleaning'>
            <li className=' text-slate-300 cursor-pointer hover:text-green-300'>
              {t("Window Cleaning")}
            </li>
          </Link>

          <Link to='/services/furniture-cleaning'>
            <li className=' text-slate-300 cursor-pointer hover:text-green-300'>
              {t("Furniture Cleaning")}
            </li>
          </Link>

          <Link to='/services/carpet-cleaning'>
            <li className=' text-slate-300 cursor-pointer hover:text-green-300'>
              {t("Carpet Cleaning")}
            </li>
          </Link>

          <Link to='/services/after-cleaning'>
            <li className=' text-slate-300 cursor-pointer hover:text-green-300'>
              {t("After Construction Cleaning")}
            </li>
          </Link>
        </ul>
      </div>

      <div className='flex flex-col justify-center items-start lg:items-center w-full lg:w-[20%]'>
        <ul className='flex flex-col justify-center items-start gap-3'>
          <h1 className='text-xl font-semibold'>{t("Help")}</h1>
          <Link to='/support'>
            <li className=' text-slate-300 cursor-pointer hover:text-green-300'>
              {t("Customer Support")}
            </li>
          </Link>
          <Link to='/terms'>
            <li className=' text-slate-300 cursor-pointer hover:text-green-300'>
              {t("Terms & Conditions")}
            </li>
          </Link>
        </ul>
      </div>

      <div className='flex flex-col justify-center items-start lg:items-center w-full lg:w-[20%] lg:mb-0 mb-5'>
        <ul className='flex flex-col justify-center items-start gap-3'>
          <h1 className='text-xl font-semibold'>{t("Menu")}</h1>

          <Link to='/'>
            <li className='text-slate-300 cursor-pointer hover:text-green-300'>
              {t("Home")}
            </li>
          </Link>

          <Link to='/services'>
            <li className='text-slate-300 cursor-pointer hover:text-green-300'>
              {t("Services")}
            </li>
          </Link>

          <Link to='/about-us'>
            <li className='text-slate-300 cursor-pointer hover:text-green-300'>
              {t("About")}
            </li>
          </Link>
        </ul>
      </div>

      <div className='text-slate-300 cursor-pointer hover:text-green-300 text-xs absolute bottom-5'>
        <p className='text-center'>
          @{new Date().getFullYear()} Cre8tive Tech - {t("All right reserved")}
        </p>
      </div>
    </section>
  );
};

export default Footer;
